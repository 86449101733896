import Vue from 'vue';
import VueRouter from 'vue-router';


const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/login',
        name:'login',
        component: ()=>import('../views/login/login'),
        meta: {
            title: '创扶企规宝开放平台-登录',
        }
    },
    {
        path: '/home',
        name:'home',
        component: ()=>import('../views/home/home'),
        meta: {
            title: '创扶企规宝开放平台',
        },
        children:[
            {
                path: '/',
                redirect: '/open-auth'
            }
            ,
            {
                path:"/open-auth",
                name:'openAuth',
                component: ()=>import('../views/open/update'),
                meta: {
                    title: '创扶企规宝开放平台',
                },
            },
            {
                path:"/open-details",
                name:'openDetails',
                component: ()=>import('../views/open/index'),
                meta: {
                    title: '创扶企规宝开放平台',
                },
            }
        ]
    }
]
Vue.use(VueRouter)


const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode:'history',
    linkActiveClass: 'active'
})



//新版本记录路由导航控制台会报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
