<template>
  <div id="app">
    <router-view :key="key"></router-view>
  </div>
</template>

<script>
import {getToken, getOpenId} from "@/utils/authUtils";

export default {
  name: 'App',
  computed: {
    key() {
      return this.$route.fullPath
    }
  },
  created() {
    let token = getToken();
    let openId = getOpenId();
    if (!token && !openId) {
      this.$router.replace('/login');
    }
  },
  methods: {

  },
}
</script>

<style>

</style>
