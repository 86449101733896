import Cookies from 'js-cookie'

/**
 * 定义TOKEN的 key
 * @type {string}
 */
const TOKEN_KEY = 'token';
/**
 * 定义用户Id key
 * @type {string}
 */
const OPEN_ID_KEY = 'openId';
/**
 * 定义用户名称 key
 * @type {string}
 */
const OPEN_NAME_KEY = 'openName';



/**************************************************** set ************************************************************/
/**
 * 设置token到cookies里面
 * @param params
 * @returns {*}
 */
export function setToken(params) {
    return Cookies.set(TOKEN_KEY, params)
}

/**
 * 设置用户id到cookies里面
 * @param params
 * @returns {*}
 */
export function setOpenId(params) {
    return Cookies.set(OPEN_ID_KEY, params)
}

/**
 * 设置员工名称到cookies里面
 * @param params
 * @returns {*}
 */
export function setOpenName(params) {
    return Cookies.set(OPEN_NAME_KEY, params)
}


/**************************************************** get ************************************************************/

/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
    return Cookies.get(TOKEN_KEY)
}

/**
 * 获取用户id
 * @returns {*}
 */
export function getOpenId() {
    return Cookies.get(OPEN_ID_KEY)
}

/**
 * 获取用户名称
 * @returns {*}
 */
export function getOpenName() {
    return Cookies.get(OPEN_NAME_KEY)
}

/**************************************************** remove ************************************************************/
/**
 * 移除用户id
 * @returns {*}
 */
export function removeOpenId() {
    return Cookies.remove(OPEN_ID_KEY)
}

/**
 * 移除token
 * @returns {*}
 */
export function removeToken() {
    return Cookies.remove(TOKEN_KEY)
}

/**
 * 移除用户名称
 * @returns {*}
 */
export function removeOpenName() {
    return Cookies.remove(OPEN_NAME_KEY)
}



/**
 * 移除所有
 * @returns {*}
 */
export function remove() {
    Cookies.remove(OPEN_ID_KEY)
    Cookies.remove(OPEN_NAME_KEY)
    Cookies.remove(TOKEN_KEY)
}
